import React, { useEffect, useRef } from 'react';
import { FormInstance } from 'antd';
import { FormInput } from 'common/components/Form/FormInput';
import { FormRadio } from 'common/components/Form/FormRadio';
import { IFormValues, IQuestionnaireAnswer } from 'common/models/formBuilder.models';
import { AddOptionBtn } from 'common/components/Form/AddOptionBtn';
import { FormCheckbox } from 'common/components/Form/FormCheckbox';
import { baseValidationRules, maxSymptomsCount, placeholders, textValidationRules } from 'common/const/questionnaire.const';
import { FormSelectSingle } from 'common/components/Form/FormSelectSingle';
import { FormDatePicker } from 'common/components/Form/FormDatePicker';
import { FormSelectGroup } from 'common/components/Form/FormSelectGroup';
import { compareFields } from 'common/utils/compareFields';
import { generateFormItemClassName } from 'common/utils/generateFormItemClassName';
import { FORMS_CUSTOM_ITEMS_MAX_COUNT } from 'common/config';
import { FormLookup } from 'common/components/Form/FormLookup';
import { IQuestionnaireElement } from 'entities/PatientSessions/PatientSession.models';

const getAnswers = (answers: IQuestionnaireAnswer[], questionId: string) => {
  return answers?.find((answer: IQuestionnaireAnswer) => answer.questionId === questionId)?.answers || [];
};

export const getFormControl = (
  item: IQuestionnaireElement,
  answers: IQuestionnaireAnswer[],
  element?: IQuestionnaireAnswer,
  elements?: IQuestionnaireElement[],
  checkboxValue?: string[],
  formController?: FormInstance<IFormValues>
): JSX.Element => {
  switch (item.type) {
    case 'datePicker': {
      return <FormDatePicker name={item.textId} label={item.question} />;
    }
    case 'text': {
      return placeholders.includes(item.question) ? (
        <FormInput
          name={item.textId}
          placeholder={item.question}
          type="text"
          rules={item.required === false ? undefined : textValidationRules()}
        />
      ) : (
        <FormInput
          name={item.textId}
          placeholder={item.question}
          label={item.question}
          type="text"
          rules={item.required === false ? undefined : textValidationRules()}
        />
      );
    }
    case 'radio': {
      return item.answers ? (
        <FormRadio
          className={generateFormItemClassName(item.textId, item.type)}
          name={item.textId}
          label={item.question}
          radioButtons={item.answers}
          tooltip={item.hover}
        />
      ) : (
        <div></div>
      );
    }
    case 'checkbox': {
      const patientAnswers = getAnswers(answers, item.textId);
      // Here we check if patient added any custom items and draw them alongside with initial
      const buttons: string[] = [...new Set([...item.answers, ...patientAnswers])];
      return element ? (
        <FormCheckbox
          className="form__checkbox"
          name={item.textId}
          label={item.question}
          checkboxButtons={buttons}
          required={item.required}
        />
      ) : item.answers ? (
        <FormCheckbox
          className="form__checkbox"
          name={item.textId}
          label={item.question}
          checkboxButtons={buttons}
          checkboxValue={checkboxValue}
          required={item.required}
        />
      ) : (
        <div></div>
      );
    }
    case 'number': {
      return (
        <FormInput
          name={item.textId}
          type="number"
          label={item.question}
          placeholder={item.placeholder || item.question}
          rules={!item.validators ? baseValidationRules() : undefined}
          validators={item.validators}
        />
      );
    }
    case 'checkbox_limited': {
      if (item.textId === 'baseline_head_30') {
        const bh27Answers = getAnswers(answers, 'baseline_head_27');
        const answersUnion: string[] = element ? [...bh27Answers, ...element?.answers] : bh27Answers;

        const fiveDIff = maxSymptomsCount - bh27Answers?.length;
        const answerObj = answers?.find((answer: IQuestionnaireAnswer) => answer.questionId === 'baseline_head_30');

        if (answerObj?.answers.length > fiveDIff) {
          answerObj?.answers.pop();
        }

        return answersUnion.length > maxSymptomsCount ? (
          <FormCheckbox
            className="form__checkbox"
            name={item.textId}
            label={getQuestionTextForCheckboxLimitedQuestion(fiveDIff)}
            checkboxButtons={element?.answers}
            max={fiveDIff}
            answerObj={answerObj}
            rules={[
              { type: 'array', min: fiveDIff, message: `Please select ${fiveDIff - answerObj?.answers.length} more symptoms ` },
            ]}
          />
        ) : (
          <div></div>
        );
      }

      const answerObj = answers?.find((answer: IQuestionnaireAnswer) => answer.questionId === item.textId);

      if (answerObj?.answers.length > maxSymptomsCount) {
        answerObj?.answers.pop();
      }

      return element?.answers.length > maxSymptomsCount ? (
        <FormCheckbox
          className="form__checkbox"
          name={item.textId}
          label={getQuestionTextForCheckboxLimitedQuestion(maxSymptomsCount)}
          checkboxButtons={element?.answers}
          max={maxSymptomsCount}
          answerObj={answerObj}
          rules={[
            {
              type: 'array',
              min: maxSymptomsCount,
              message: `Please select ${maxSymptomsCount - answerObj?.answers.length} more symptoms `,
            },
          ]}
        />
      ) : (
        <div></div>
      );
    }
    case 'select': {
      return element?.answers?.length || item?.answers?.length ? (
        <FormSelectSingle
          name={item.textId}
          options={element?.answers || item.answers}
          label={item.question}
          placeholder={item.question}
        />
      ) : (
        <div></div>
      );
    }
    case 'select_group': {
      if (item.textId === 'baseline_head_31' || item.textId === 'baseline_head_38') {
        const additionalAnswers = getAnswers(answers, 'baseline_head_27');

        let checkboxLimitedElement = undefined;

        if (formController && element) {
          checkboxLimitedElement = formController.getFieldInstance(element.questionId);
        }

        const answersUnion: string[] = [
          ...additionalAnswers,
          ...(checkboxLimitedElement !== undefined && element?.answers.length
            ? element?.answers
            : getAnswers(answers, 'baseline_head_29')),
        ];

        return answersUnion.length ? (
          <FormSelectGroup
            name={item.textId}
            label={item.question}
            options={item.answers}
            answers={answersUnion}
            placeholder={item.placeholder || ''}
          />
        ) : (
          <div></div>
        );
      } else {
        const fallbackQuestionnareId = elements?.find((element: IQuestionnaireElement) => element.textId.endsWith('25'))?.textId;

        const fallbackAnswers = fallbackQuestionnareId ? getAnswers(answers, fallbackQuestionnareId) : [];
        return element?.answers.length || fallbackAnswers.length ? (
          <FormSelectGroup
            name={item.textId}
            label={item.question}
            options={item.answers}
            answers={element?.answers.length ? element?.answers : fallbackAnswers}
            placeholder={item.placeholder || ''}
          />
        ) : (
          <div></div>
        );
      }
    }
    case 'lookup': {
      return <FormLookup name={item.textId} label={item.question} />;
    }
    default:
      return <div></div>;
  }
};

export const renderFormControls = (
  elements: IQuestionnaireElement[],
  answers: IQuestionnaireAnswer[],
  addItemResponses: (question: string, value: string) => void,
  checkboxValue: string[],
  formController?: FormInstance<IFormValues>
): React.ReactNode => {
  const canAddCustomItem = checkboxValue.length < FORMS_CUSTOM_ITEMS_MAX_COUNT;
  return elements.map((item) => {
    switch (true) {
      case !!item.trigger: {
        const element = answers.find((answer: IQuestionnaireAnswer) => answer.questionId === item.trigger?.questionId);
        const basedOn = item.basedOn?.length
          ? answers.find((answer: IQuestionnaireAnswer) => answer.questionId === item.basedOn)
          : undefined;
        if (item.trigger?.compare && typeof item.trigger.value === 'number') {
          if (compareFields(item.trigger.compare, item.trigger.value, element?.answers)) {
            return (
              <div key={item.textId}>
                {getFormControl(item, answers, basedOn, elements, checkboxValue)}
                {item.addOption && <AddOptionBtn name={item.addOption} question={item.question} onChange={addItemResponses} />}
              </div>
            );
          }
        } else {
          if (item.trigger?.value instanceof Array) {
            return (
              (element?.answers.includes(item.trigger?.value.join()) || item.trigger?.value.includes(element?.answers)) && (
                <div key={item.textId}>
                  {getFormControl(item, answers, basedOn, elements, checkboxValue)}
                  {canAddCustomItem && item.addOption && (
                    <AddOptionBtn name={item.addOption} question={item.question} onChange={addItemResponses} />
                  )}
                </div>
              )
            );
          }
        }
        return;
      }
      case !!item.basedOn: {
        const element = answers.find((answer: IQuestionnaireAnswer) => answer.questionId === item.basedOn);
        return <div key={item.textId}>{getFormControl(item, answers, element, elements, undefined, formController)}</div>;
      }
      case item.type === 'subhead': {
        const title = useRef<HTMLSpanElement>(null);

        useEffect(() => {
          if (item.question && title.current) {
            title.current.innerHTML = item.question;
          }
        }, [item.question]);

        return (
          <div key={item.textId} className="form__subhead mb-8">
            <span ref={title}>{item.question}</span>
          </div>
        );
      }
      case item.type === 'tip': {
        const labelRef = useRef<HTMLSpanElement>(null);

        // eslint-disable-next-line sonarjs/no-identical-functions
        useEffect(() => {
          if (item.question && labelRef.current) {
            labelRef.current.innerHTML = item.question;
          }
        }, [item.question]);

        return (
          <div key={item.textId} className="form__tip mb-8">
            <span ref={labelRef}>{item.question}</span>
          </div>
        );
      }
      default: {
        return (
          <div key={item.textId}>
            {getFormControl(item, answers, undefined, elements, checkboxValue)}
            {canAddCustomItem && item.addOption && (
              <AddOptionBtn name={item.addOption} question={item.question} onChange={addItemResponses} />
            )}
          </div>
        );
      }
    }
  });
};

export const getQuestionTextForCheckboxLimitedQuestion = (simptomsCount: number): string => {
  return `Which are your ${simptomsCount} most bothersome symptoms to track on a daily basis?`;
};
