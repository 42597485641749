import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Layout } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import isEqual from 'lodash.isequal';
import { getQuestionTextForCheckboxLimitedQuestion, renderFormControls } from 'common/helpers/formBuilder.helper';
import { ManageBtns } from 'common/components/Form/ManageBtns';
import { CopyAnswers } from 'common/components/Form/FormCopyAnswers';
import {
  IQuestionnaireAnswer,
  IFormValues,
  IPatientAnswer,
  ISelectGroupAnswer,
  IBaseParams,
  ICopyAnswersRequest,
} from 'common/models/formBuilder.models';
import { QUESTIONNAIRE_UPDATE_INTERVAL } from 'common/config';
import { answerIsObject } from 'common/utils/answerIsObject';
import { useTimer } from 'common/hooks/useTimer';
import { checkboxLimitedQuestionIds, ignoredQuestionTypesWithErrors } from 'common/const/questionnaire.const';
import {
  IAvailableNonHeadQuestionnaire,
  IQuestionnaireElement,
  IQuestionnaireModel,
} from 'entities/PatientSessions/PatientSession.models';

interface IComponentProps {
  questionnaire: IQuestionnaireModel;
  patientAnswers: IPatientAnswer[];
  setPatientAnswers: (value: IPatientAnswer[]) => void;
  handleSubmit: (params: IBaseParams, update?: boolean) => void;
  patientSessionId?: string;
  getAnswersCopy: (params: ICopyAnswersRequest) => Promise<IQuestionnaireModel>;
}

export const FormBuilder: React.FC<IComponentProps> = (props) => {
  const { questionnaire, handleSubmit, patientAnswers, patientSessionId, getAnswersCopy } = props;
  const [elements, setElements] = useState<IQuestionnaireElement[]>(questionnaire.elements);
  const [answers, setAnswers] = useState<IQuestionnaireAnswer[]>([]);
  const [answersCopy, setAnswersCopy] = useState<IQuestionnaireAnswer[]>(answers);
  const [checkboxValues, setCheckboxValues] = useState<string[]>([]);
  const [showCopyAnswersModal, setShowCopyAnswersModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [commentCopy, setCommentCopy] = useState<string>(comment);
  const [formController] = useForm<IFormValues>();
  const [timer, timerLimit] = useTimer(QUESTIONNAIRE_UPDATE_INTERVAL);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const addItemResponses = (question: string, value: string) => {
    const element = elements.find((item) => item.question === question);
    const answer = answers.find((answer: IQuestionnaireAnswer) => answer.questionId === element?.textId);
    // If a custom item is added, and there were no previously selected elements, the answer object will be empty.
    answer ? answer.answers.push(value) : element && answers.push({ questionId: element.textId, answers: [value] });
    checkboxValues.push(value);
    setCheckboxValues(checkboxValues);
    if (element) {
      element.answers?.push(value);
      setElements([...elements]);
    }
  };

  const onChange = (values: IFormValues) => {
    // If there is any questions that depend on the changed answer and the new value doesn't equal to triggering value
    // We exclude all dependent answers from answers object
    const key = Object.keys(values)[0];
    const value = Object.values(values)[0];
    const dependentValues: string[] = questionnaire.elements
      .filter(
        (element: IQuestionnaireElement) => element.trigger?.questionId === key && element.trigger?.value.toString() !== value
      )
      .map((element: IQuestionnaireElement) => element.textId);

    const answersFiltered =
      dependentValues.length > 0
        ? answers.filter((answer: IQuestionnaireAnswer) => !dependentValues.includes(answer.questionId))
        : answers;

    // ============================================================

    Object.entries(values).forEach(([key, value]) => {
      const questionFromAnswered = answersFiltered.find((item: IQuestionnaireAnswer) => item.questionId === key);

      if (questionFromAnswered) {
        // We should generate custom question text according to patient answers if it is question with type checkbox_limited
        const questionText = checkboxLimitedQuestionIds.includes(questionFromAnswered.questionId)
          ? getQuestionTextForCheckboxLimitedQuestion(value.length)
          : questionFromAnswered.question;

        questionFromAnswered.question = questionText;

        if (answerIsObject(value)) {
          // Exclude value from answers object if it's already in it
          questionFromAnswered.answers = questionFromAnswered.answers?.filter((element: ISelectGroupAnswer) =>
            Object.keys(element).join() === Object.keys(value).join() ? false : true
          );
          // Save new value
          questionFromAnswered.answers.push(value);
        } else {
          questionFromAnswered.answers = value;
        }

        setAnswers([...answersFiltered]);
      } else {
        const questionFromQuestionnaire = questionnaire.elements.find((element) => element.textId === key);
        // We should generate custom question text according to patient answers if it is question with type checkbox_limited
        const questionText = questionFromQuestionnaire
          ? checkboxLimitedQuestionIds.includes(questionFromQuestionnaire.textId)
            ? getQuestionTextForCheckboxLimitedQuestion(value.length)
            : questionFromQuestionnaire.question
          : '';

        setAnswers([
          ...answersFiltered,
          { questionId: key, answers: answerIsObject(value) ? [value] : value, question: questionText },
        ]);
      }
    });
  };

  const handleSave = (comment: string) => {
    if (comment) {
      setComment(comment);
    }
  };

  const onSubmit = () => {
    handleSubmit({ elements: answers, comments: [{ textId: questionnaire.textId, comment }] });
  };

  const toggleCopyAnswersModal = () => {
    setShowCopyAnswersModal(!showCopyAnswersModal);
  };

  const handlePrefill = (value: string) => {
    const questionnaireId = questionnaire?.prefillOptions?.find(
      (questionnaire: IAvailableNonHeadQuestionnaire) => questionnaire.name === value
    )?.textId;
    patientSessionId &&
      questionnaireId &&
      getAnswersCopy({ id: patientSessionId, textId: questionnaireId }).then(() => {
        toggleCopyAnswersModal();
      });
  };

  useEffect(() => {
    if (timer === timerLimit && (!isEqual(answers, answersCopy) || !isEqual(comment, commentCopy))) {
      const fieldsWithErrors = formController
        .getFieldsError()
        .filter((field) => field.errors.length)
        .map((field) => field.name)
        .flat();

      const elements = answers.filter((answer: IQuestionnaireAnswer) => {
        const questionType =
          questionnaire.elements.find((element: IQuestionnaireElement) => element.textId === answer.questionId)?.type || '';

        return ignoredQuestionTypesWithErrors.includes(questionType) ? true : !fieldsWithErrors.includes(answer.questionId);
      });

      handleSubmit({ elements, comments: [{ textId: questionnaire.textId, comment }] }, true);
      setAnswersCopy(answers);
      setCommentCopy(comment);
    }
  }, [timer]);

  useEffect(() => {
    const answers: IQuestionnaireAnswer[] = [];

    if (patientAnswers.length) {
      patientAnswers.forEach((patientAnswer: IPatientAnswer) => {
        // Name will be an array only in case when it's a FormSelectGroup component answers
        const patientAnswerFieldName = patientAnswer.name instanceof Array ? patientAnswer.name[0] : patientAnswer.name;
        const question = questionnaire.elements.find(
          (element: IQuestionnaireElement) => element.textId === patientAnswerFieldName
        );
        // We should generate custom question text according to patient answers if it is question with type checkbox_limited
        const questionText = question
          ? checkboxLimitedQuestionIds.includes(question.textId)
            ? getQuestionTextForCheckboxLimitedQuestion(patientAnswer.value.length)
            : question.question
          : '';

        // Name will be an array only in case when it's a FormSelectGroup component answers
        if (patientAnswer.name instanceof Array) {
          const answer = answers.find((item: IQuestionnaireAnswer) => item.questionId === patientAnswer.name[0]);
          const value = { [patientAnswer.name[1]]: patientAnswer.value };

          if (answer) {
            answer.answers.push(value);
            answer.question = questionText;
          } else {
            answers.push({ questionId: patientAnswer.name[0], answers: [value], question: questionText });
          }
        } else {
          answers.push({
            questionId: patientAnswer.name,
            answers: patientAnswer.value,
            question: questionText,
          });
        }
      });

      setAnswers(answers);
    }
  }, [patientAnswers]);

  useEffect(() => {
    if (questionnaire.name && titleRef.current) {
      titleRef.current.innerHTML = `${questionnaire.name}`;
    }
  }, [questionnaire.name]);

  return (
    <Layout>
      <Form
        className="form"
        layout="vertical"
        onFinish={onSubmit}
        onValuesChange={onChange}
        scrollToFirstError={{ behavior: 'smooth' }}
        fields={patientAnswers}
        preserve={false}
        form={formController}
      >
        <h1 className="form__title" ref={titleRef} />
        {questionnaire.prefillOptions && (
          <Button className="mb-6" onClick={toggleCopyAnswersModal}>
            Copy answers from ...
          </Button>
        )}

        {renderFormControls(elements, answers, addItemResponses, checkboxValues, formController)}

        <ManageBtns handleSave={handleSave} />
      </Form>
      {questionnaire?.prefillOptions && (
        <CopyAnswers
          visible={showCopyAnswersModal}
          onCancel={toggleCopyAnswersModal}
          onSave={() => setShowCopyAnswersModal(!showCopyAnswersModal)}
          questionnareTitle={questionnaire.name}
          options={questionnaire.prefillOptions.map((entry: IAvailableNonHeadQuestionnaire) => entry.name)}
          handlePrefill={handlePrefill}
        />
      )}
    </Layout>
  );
};
