import { Rule } from 'antd/lib/form';
import { IQuestionnaireValidator } from 'entities/PatientSessions/PatientSession.models';

export const shortAnswer = ['Yes', 'No'];

export const reliefSelectorOptions = ['None', 'Slight', 'Moderate', 'Complete'];

export const placeholders = [
  'Provide additional explanation here',
  'Please provide any additional comments',
  'Other',
  'What improves your head/neck pain?',
  'What makes your head/neck pain worse?',
  'Enter diagnosis',
];

export const basicRequiredMessage = 'Please fill in the required field';
const basicMinLengthMessage = 'Your answer should be at least 0';

export const baseValidationRules = (): Rule[] => [{ required: true, message: basicRequiredMessage }];

export const textValidationRules = (): Rule[] => [
  { required: true, message: basicRequiredMessage },
  { type: 'string', min: 3, message: 'Your answer should be at least 3 characters' },
  { type: 'string', max: 1024, message: 'Your answer should be no longer than 1024 characters' },
];

export const numericFieldsValidators: IQuestionnaireValidator[] = [
  { type: 'min', value: 0, message: basicMinLengthMessage },
  { type: 'max', value: 10, message: 'Your answer should be less than or equal to 10' },
];

export const tempFieldValidators: IQuestionnaireValidator[] = [
  { type: 'min', value: -20, message: 'Your answer should be at least -20' },
  { type: 'max', value: 135, message: 'Your answer should be less than or equal to 135' },
];

export enum EQuestionnaireBodyAreas {
  baseline_head = 'Head/Neck',
  baseline_body_left_arm = 'Left Arm',
  baseline_body_right_arm = 'Right Arm',
  baseline_body_left_hand = 'Left Hand',
  baseline_body_right_hand = 'Right Hand',
  baseline_body_chest_abdomen_back = 'Chest/Abdomen/Back',
  baseline_body_legs = 'Legs',
  baseline_body_feet = 'Feet',
}

export const symptomsListOptions = ['None', 'Mild', 'Moderate', 'Severe'];

export const painQuestionsStaticFieldsIds = ['baseline_pain_questions_1', 'baseline_pain_questions_2'];

export const bedTimeStaticFieldsIds = ['baseline_bed_time_1'];

export const symptomsListStaticFieldsIds = ['baseline_symptoms_list_1'];

export const tooltipMessageDoze =
  'Please enter your dose in milligrams. This information can be found on your medication packaging.';
export const tooltipMessageTime = 'Please enter how many times you typically take this medication per day.';
export const tooltipMessageRelief =
  'Medication Relief Rating:\n 0 (no relief),\n 1 (slight relief),\n 2 (moderate relief),\n 3 (complete relief)';
export const tooltipMessageAffect = 'These medications will be tracked in your daily diary';

export const medicationDoseValidators = [
  {
    type: 'min',
    value: 0,
    target: 'dose',
    message: basicMinLengthMessage,
  },
  {
    type: 'max',
    value: 1000,
    target: 'dose',
    message: 'Your answer should be less than or equal to 1000',
  },
];
export const medicationTimeTakenValidators = [
  {
    type: 'min',
    value: 0,
    target: 'time_taken',
    message: basicMinLengthMessage,
  },
  {
    type: 'max',
    value: 10,
    target: 'time_taken',
    message: 'Your answer should be less than or equal to 10',
  },
];

export const DiaryHeadNonHeadQuestionnairesId = [
  'diary_head_symptoms',
  'diary_chest_abdomen_back_symptoms',
  'diary_feet_symptoms',
  'diary_left_arm_symptoms',
  'diary_left_hand_symptoms',
  'diary_legs_symptoms',
  'diary_right_arm_symptoms',
  'diary_right_hand_symptoms',
];

export const painReliefOptions: string[] = ['no relief', 'slight relief', 'moderate relief', 'complete relief'];

export const ignoredQuestionTypesWithErrors = ['checkbox', 'checkbox_limited', 'select_group'];

export const checkboxLimitedQuestionIds = [
  'baseline_head_30',
  'baseline_body_chest_abdomen_back_26',
  'baseline_body_feet_26',
  'baseline_body_left_arm_26',
  'baseline_body_left_hand_26',
  'baseline_body_legs_26',
  'baseline_body_right_arm_26',
  'baseline_body_right_hand_26',
];

export const maxSymptomsCount = 5;
